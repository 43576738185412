import { styled } from '@mui/material'
// ? Material Ui
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Image from 'next/image'
import React from 'react'
import { getStrapiImage, getStrapiImagePlaceholder } from '../../lib/api'

const Item = styled(Paper)(({ theme }) => ({
  '&:hover': {
    boxShadow: '0 15px 25px 0 rgba(0,0,0,0.1)',
    background: 'rgba(255, 255, 255, 0.1)',
  },
  padding: theme.spacing(3),
}))

const Count: React.FC<Props<Participants>> = ({ data }) => {
  return (
    <Box sx={{ flexGrow: 1, py: { xs: 6, md: 6 } }}>
      <Container>
        <Stack spacing={2} sx={{ px: { xs: 0, md: 12, lg: 20 } }}>
          <Stack alignItems="center">
            <Typography
              variant="h1"
              textAlign="center"
              noWrap
              fontWeight={800}
              sx={{ width: 1 }}
              color="accent.main"
            >
              {data?.sub}
            </Typography>
            <Typography variant="h4" fontWeight={700} sx={{ mt: '-70px' }}>
              {data?.titre}
            </Typography>
          </Stack>
          <Typography
            color="text.secondary"
            sx={{ mt: 4 }}
            textAlign="center"
            gutterBottom
          >
            {data?.description}
          </Typography>
        </Stack>
        <Grid container spacing={2} sx={{ my: 4 }}>
          {data?.liste?.map((el, i) => (
            <Grid item xs={12} sm={6} md={4} key={el.id}>
              <Item key={i}>
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  spacing={2}
                  direction="row"
                >
                  <Stack
                    key={el?.id}
                    sx={{ width: 80, height: 80, position: 'relative' }}
                  >
                    <Image
                      src={getStrapiImage(el.media)}
                      blurDataURL={getStrapiImagePlaceholder(el.media)}
                      placeholder="blur"
                      alt="updev"
                      layout="fill"
                      objectFit="contain"
                    />
                  </Stack>
                  <Divider orientation="vertical" flexItem />
                  <Stack>
                    <Typography component="h2" variant="h4" gutterBottom>
                      {el?.nombre}
                    </Typography>
                    <Typography component="h2" fontWeight={700} gutterBottom>
                      {el?.titre}
                    </Typography>
                  </Stack>
                </Stack>
              </Item>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

export default Count
